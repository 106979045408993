<template>
    <form-field
        v-click-outside="onBlur"
        :shrink="props.shrink"
        :field-class="props.fieldClass"
    >
        <slot name="before-label" />

        <label
            v-if="props.label"
            :for="props.id"
            class="form-label"
        >
            {{ props.label }}
            <span
                v-if="props.required"
                class="text-red-600"
            >*</span>
        </label>

        <div class="relative flex items-center">
            <slot name="before-input" />

            <input
                :id="props.id"
                ref="inputElement"
                v-model="modelValue"
                :style="{
                    'padding-right': paddingRight ? `${paddingRight}px` : undefined,
                }"
                :type="props.type"
                :placeholder="props.placeholder"
                :autocomplete="props.autocomplete"
                :autofocus="props.autofocus"
                :required="props.required"
                :disabled="props.disabled"
                :readonly="props.readonly"
                :name="props.name"
                :data-1p-ignore="props.disablePasswordManager ? 'true' : undefined"
                class="form-input"
                :class="{
                    'form-input--icon': props.icon,
                    'form-input--suffix': props.suffix,
                }"
                v-bind="$attrs"
                @focus="onFocus"
                @keydown="onKeydown"
                @change="emits('change', $event)"
            />

            <Loading
                v-if="props.loading"
                color="media"
                class="absolute align-y left-4 pointer-events-none"
            />

            <font-awesome-icon
                v-else-if="props.icon"
                :icon="props.icon"
                class="absolute align-y left-4 opacity-50 pointer-events-none"
            />

            <slot
                :focused="focused"
                :on-focus="onFocus"
                :on-blur="onBlur"
            />

            <span
                v-if="props.suffix"
                ref="suffixElement"
                class="absolute right-2 align-y text-xs opacity-60 pointer-events-none"
            >{{ props.suffix }}</span>
        </div>

        <p
            v-if="props.instructions"
            class="form-instructions"
        >
            {{ props.instructions }}
        </p>

        <form-field-errors :errors="errors" />
    </form-field>
</template>

<script setup lang="ts">
    import type { Icon } from '~/types';
    import { AsYouType } from 'libphonenumber-js';

    export interface Props {
        id: string;
        name?: string;
        type?: string;
        label?: string;
        instructions?: string;
        icon?: Icon;
        autocomplete?: string;
        autofocus?: boolean;
        placeholder?: string;
        suffix?: string;
        required?: boolean;
        disabled?: boolean;
        readonly?: boolean;
        loading?: boolean;
        errors?: string[] | string;
        shrink?: boolean;
        fieldClass?: string | string[] | Record<string, boolean> | boolean;
        disablePasswordManager?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        name: undefined,
        label: undefined,
        instructions: undefined,
        icon: undefined,
        autocomplete: undefined,
        autofocus: undefined,
        placeholder: undefined,
        suffix: undefined,
        required: false,
        disabled: false,
        readonly: false,
        loading: false,
        errors: undefined,
        shrink: false,
        fieldClass: undefined,
        type: 'text',
        disablePasswordManager: false,
    });

    const emits = defineEmits([ 'focus', 'blur', 'keydown', 'change' ]);

    const inputElement = ref<HTMLInputElement | null>(null);
    const suffixElement = ref<HTMLInputElement | null>(null);

    const paddingRight = ref<number | undefined>();

    const modelValue = defineModel<any>();
    watch(modelValue, value => {
        if (props.type === 'tel') {
            modelValue.value = (new AsYouType('AU')).input(value as string);
        }
    });

    const focused = ref<boolean>(false);

    const onFocus = (event: FocusEvent) => {
        focused.value = true;
        emits('focus', event);
    };

    const onKeydown = (event: KeyboardEvent) => {
        focused.value = true;
        emits('keydown', event);
    };

    const onBlur = (event: FocusEvent) => {
        focused.value = false;
        emits('blur', event);
    };

    onMounted(async() => {
        if (props.autofocus && inputElement.value) {
            await nextTick();
            inputElement.value.focus();
        }

        if (props.suffix && suffixElement.value) {
            paddingRight.value = suffixElement.value.offsetWidth + 16;
        }
    });
</script>

<style lang="postcss">
    .form-label {
        @apply block w-full mb-1;
        @apply text-sm font-medium;
    }

    .form-instructions {
        @apply block w-full mt-1 opacity-60;
        @apply text-xs font-medium;
    }

    .form-input {
        @apply block w-full px-4 py-2 appearance-none;
        @apply bg-gray-100 border-gray-200 border-2 rounded-md;
        @apply focus:outline-none focus:ring-2 focus:ring-primary-600 focus:border-transparent;
        @apply dark:bg-gray-800 dark:border-gray-700 dark:text-white;

        @media (prefers-color-scheme: dark) {
            color-scheme: dark;
        }

        &--icon {
            @apply pl-10;
        }

        &--loading {
            @apply appearance-none;

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }
        }

        &--valid:not(.unstyled-input) {
            @apply ring-2 ring-green-500 dark:ring-green-700;
        }

        &:read-only {
            @apply text-gray-600 dark:text-gray-400;
        }

        &:disabled {
            @apply opacity-75;
        }

        &::placeholder {
            @apply opacity-100 text-sm text-gray-400;
            @apply dark:text-gray-600;
        }
    }
</style>
